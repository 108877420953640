.AlertInformation {
  margin-bottom: 20px;
  text-align: center;
}

.InputUpload {
  border: 1px #d1d1d1 dashed;
  padding: 12px;
}

.TableContainer {
  overflow-x: scroll;
  max-width: 100%;
  border: 1px solid #ababab;
  padding-bottom: 24px;
}

.TableHeader {
  height: 60px;
}

.TableBodyTrame {
  height: 75px;
}

.TableColumns th,
.TableHeader th,
.TableBodyTrame td {
  border-right: 1px solid #ababab;
  border-bottom: 1px solid #ababab;
}

.BackgroundGrey {
  background-color: #f9f9f9;
}

.WarningBackground {
  background-color: #fff6f6;
}

.ContainerIcons {
  display: flex;
}

.ContainerIcons div:last-child {
  margin-left: 10px;
}

.IconAction {
  background-color: #fff;
  padding: 12px 8px 6px;
  width: 40px;
  border-radius: 50px;
  margin: auto;
  border: 1px solid #ababab;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.IconAction:hover {
  cursor: pointer;
  background-color: #ef374b;
  color: #fff;
  border-color: #fff;
}

.AddRow {
  text-align: center;
  padding-top: 16px;
}

.TextError {
  font-size: 16px;
}

.Center {
  text-align: center;
}

/* SCROLLER */
.Scroller {
  position: fixed;
  right: 12px;
  top: 50%;
}

.Scroller button:first-child {
  margin-bottom: 10px;
}

.Button {
  background-color: white;
  cursor: pointer;
  display: flex;
  border-radius: 50px;
  border: solid 1px #ccc;
  padding: 6px 3px 0;
  transition: .25s all;
}

.Button:hover {
  border-color: white;
  color: white;
  background: linear-gradient(to bottom, #6371c7, #5563c1);
}
