.StatusBadge {
  vertical-align: middle;
  height: 10px;
  width: 10px;
  border-radius: 30px;
  display: inline-block;
  margin: auto;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.green {
  background-color: green;
}

th {
  padding: 0 5px;
}
